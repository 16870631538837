var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"table-list"},[_c('div',{staticClass:"mb-1",staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('b-breadcrumb',{staticClass:"breadcrumb-slash mb-1"},[_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v(" ประวัติคำสั่งซื้อ ")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v(" รายการคำสั่งซื้อ ")])],1),_c('div')],1),_c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',{staticClass:"mb-1"},[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"3"}}),_c('b-col',{attrs:{"cols":"7","md":"7"}},[_c('b-form-input',{staticClass:"d-inline-block mr-1",attrs:{"placeholder":"ค้นหาทั้งหมด… (หมายเลข, ชื่อ, เบอร์)"},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('b-col',{attrs:{"cols":"3","md":"2"}},[_c('div',{staticClass:"d-flex"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.refetchData()}}},[_vm._v(" โหลดข้อมูล ")])],1)])],1),_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"3"}}),_c('b-col',{attrs:{"cols":"12","md":"9"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.statusOptions,"placeholder":"All"},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_c('span',{staticClass:"text-truncate overflow-hidden"},[_vm._v(" "+_vm._s(label)+" ")])]}}]),model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex"},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i',
                      time_24hr: true,
                    },"placeholder":"วันที่เริ่มต้น"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 133, 133, 0.15)'),expression:"'rgba(113, 133, 133, 0.15)'",modifiers:{"400":true}}],on:{"click":function($event){_vm.date = null}}},[_vm._v(" X ")])],1)]),_c('b-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex"},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i',
                      time_24hr: true,
                    },"placeholder":"วันที่สิ้นสุด"},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}}),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 133, 133, 0.15)'),expression:"'rgba(113, 133, 133, 0.15)'",modifiers:{"400":true}}],on:{"click":function($event){_vm.dateTo = null}}},[_vm._v(" X ")])],1)])],1)],1)],1)],1),_c('b-table',{ref:"refInvoiceListTable",staticClass:"position-relative",attrs:{"items":_vm.fetchInvoices,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","sort-by":_vm.sortBy,"show-empty":"","empty-text":"ไม่พบข้อมูล","sort-desc":_vm.isSortDirDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"head(invoiceStatus)",fn:function(){return [_c('feather-icon',{staticClass:"mx-auto",attrs:{"icon":"TrendingUpIcon"}})]},proxy:true},{key:"cell(orderNo)",fn:function(data){return [_c('b-link',{staticClass:"font-weight-bold",attrs:{"to":{
              name: 'history-orderpreview',
              params: { id: data.item.orderId },
            }}},[_vm._v(" #"+_vm._s(data.item.orderNo)+" ")])]}},{key:"cell(index)",fn:function(data){return [_vm._v(" "+_vm._s((_vm.currentPage - 1) * _vm.perPage + data.index + 1)+" ")]}},{key:"cell(invoiceStatus)",fn:function(data){return [_c('b-avatar',{attrs:{"id":("invoice-row-" + (data.item.id)),"size":"32","variant":("light-" + (_vm.resolveInvoiceStatusVariantAndIcon(data.item.invoiceStatus)
                .variant))}},[_c('feather-icon',{attrs:{"icon":_vm.resolveInvoiceStatusVariantAndIcon(data.item.invoiceStatus)
                  .icon}})],1),_c('b-tooltip',{attrs:{"target":("invoice-row-" + (data.item.id)),"placement":"top"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(data.item.invoiceStatus)+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v("Balance: "+_vm._s(data.item.balance))]),_c('p',{staticClass:"mb-0"},[_vm._v("Due Date: "+_vm._s(data.item.dueDate))])])]}},{key:"cell(client)",fn:function(data){return [_c('b-media',{attrs:{"vertical-align":"center"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"32","src":data.item.avatar,"text":_vm.avatarText(data.item.client.name),"variant":("light-" + (_vm.resolveClientAvatarVariant(
                  data.item.invoiceStatus
                )))}})]},proxy:true}],null,true)},[_c('span',{staticClass:"font-weight-bold d-block text-nowrap"},[_vm._v(" "+_vm._s(data.item.client.name)+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(data.item.client.companyEmail))])])]}},{key:"cell(issuedDate)",fn:function(data){return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(data.value)+" ")])]}},{key:"cell(balance)",fn:function(data){return [(data.value === 0)?[_c('b-badge',{attrs:{"pill":"","variant":"light-success"}},[_vm._v(" Paid ")])]:[_vm._v(" "+_vm._s(data.value)+" ")]]}},{key:"cell(orderLists)",fn:function(data){return [_c('div',{domProps:{"innerHTML":_vm._s(data.item.orderDetail)}})]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_c('feather-icon',{staticClass:"mx-1",attrs:{"id":("invoice-row-" + (data.item.id) + "-preview-icon"),"icon":"EyeIcon","size":"16"},on:{"click":function($event){return _vm.$router.push({
                  name: 'history-orderpreview',
                  params: { id: data.item.orderId },
                })}}})],1)]}},{key:"cell(isPrinted)",fn:function(data){return [(data.item.isPrinted === true)?_c('span',[_vm._v("ปริ๊นแล้ว")]):_c('span',[_vm._v("ยังไม่ได้ปริ๊น")])]}}])}),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"text-muted"},[_vm._v(" แสดง "+_vm._s(_vm.dataMeta.to)+" จาก "+_vm._s(_vm.dataMeta.of)+" รายการ ")])])],1)],1)],1),_c('b-modal',{staticClass:"model-table",attrs:{"id":"modal-orderlist","centered":"","title":"รายการสั่งพิมพ์","ok-only":"","ok-title":"ตกลง","size":"xl"},on:{"ok":_vm.handleModalOk}},[_c('b-table',{ref:"refInvoiceListTable",staticClass:"position-relative model-table",attrs:{"items":_vm.fetchInvoices,"responsive":"","fields":_vm.tableColumnsPrint,"primary-key":"id","sort-by":_vm.sortBy,"show-empty":"","empty-text":"ไม่พบข้อมูล","sort-desc":_vm.isSortDirDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"head(radio)",fn:function(){return [_c('b-form-checkbox',{attrs:{"checked":true},on:{"input":_vm.selectedAll}})]},proxy:true},{key:"cell(index)",fn:function(data){return [_vm._v(" "+_vm._s(data.index + 1)+" ")]}},{key:"cell(radio)",fn:function(data){return [_c('b-form-checkbox',{attrs:{"value":data.item},model:{value:(_vm.selectedPrint),callback:function ($$v) {_vm.selectedPrint=$$v},expression:"selectedPrint"}})]}},{key:"cell(id)",fn:function(data){return [_c('b-link',{staticClass:"font-weight-bold",attrs:{"to":{
              name: 'apps-invoice-preview',
              params: { id: data.item.id },
            }}},[_vm._v(" #"+_vm._s(data.value)+" ")])]}},{key:"cell(invoiceStatus)",fn:function(data){return [_c('b-avatar',{attrs:{"id":("invoice-row-" + (data.item.id)),"size":"32","variant":("light-" + (_vm.resolveInvoiceStatusVariantAndIcon(data.item.invoiceStatus)
                .variant))}},[_c('feather-icon',{attrs:{"icon":_vm.resolveInvoiceStatusVariantAndIcon(data.item.invoiceStatus)
                  .icon}})],1),_c('b-tooltip',{attrs:{"target":("invoice-row-" + (data.item.id)),"placement":"top"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(data.item.invoiceStatus)+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v("Balance: "+_vm._s(data.item.balance))]),_c('p',{staticClass:"mb-0"},[_vm._v("Due Date: "+_vm._s(data.item.dueDate))])])]}},{key:"cell(client)",fn:function(data){return [_c('b-media',{attrs:{"vertical-align":"center"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"32","src":data.item.avatar,"text":_vm.avatarText(data.item.client.name),"variant":("light-" + (_vm.resolveClientAvatarVariant(
                  data.item.invoiceStatus
                )))}})]},proxy:true}],null,true)},[_c('span',{staticClass:"font-weight-bold d-block text-nowrap"},[_vm._v(" "+_vm._s(data.item.client.name)+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(data.item.client.companyEmail))])])]}},{key:"cell(issuedDate)",fn:function(data){return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(data.value)+" ")])]}},{key:"cell(balance)",fn:function(data){return [(data.value === 0)?[_c('b-badge',{attrs:{"pill":"","variant":"light-success"}},[_vm._v(" Paid ")])]:[_vm._v(" "+_vm._s(data.value)+" ")]]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"id":("invoice-row-" + (data.item.id) + "-send-icon"),"icon":"SendIcon","size":"16"}}),_c('b-tooltip',{staticClass:"cursor-pointer",attrs:{"title":"Send Invoice","target":("invoice-row-" + (data.item.id) + "-send-icon")}}),_c('feather-icon',{staticClass:"mx-1",attrs:{"id":("invoice-row-" + (data.item.id) + "-preview-icon"),"icon":"EyeIcon","size":"16"},on:{"click":function($event){return _vm.$router.push({
                  name: 'apps-invoice-preview',
                  params: { id: data.item.id },
                })}}}),_c('b-tooltip',{attrs:{"title":"Preview Invoice","target":("invoice-row-" + (data.item.id) + "-preview-icon")}}),_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"p-0","no-caret":"","right":_vm.$store.state.appConfig.isRTL},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',[_c('feather-icon',{attrs:{"icon":"DownloadIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Download")])],1),_c('b-dropdown-item',{attrs:{"to":{
                  name: 'apps-invoice-edit',
                  params: { id: data.item.id },
                }}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Edit")])],1),_c('b-dropdown-item',[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Delete")])],1),_c('b-dropdown-item',[_c('feather-icon',{attrs:{"icon":"CopyIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Duplicate")])],1)],1)],1)]}},{key:"cell(isPrinted)",fn:function(data){return [(data.item.isPrinted === true)?_c('span',[_vm._v("ปริ๊นแล้ว")]):_c('span',[_vm._v("ยังไม่ได้ปริ๊น")])]}}])})],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"print-data p-0 m-0",staticStyle:{"opacity":"1","z-index":"9"}},[_c('b-row',{staticClass:"p-0 m-0"},_vm._l((_vm.selectedPrint),function(item,index){return _c('b-col',{key:index,staticClass:"m-0",staticStyle:{"height":"35.97rem","display":"flex","align-items":"center","border":"1px solid black"},attrs:{"cols":"4"}},[_c('div',{staticStyle:{"display":"block","font-size":"1.5rem","color":"black"}},[_c('div',[_c('b',[_vm._v(_vm._s(item.contactName))])]),_c('div',[_vm._v(_vm._s(item.contactAddress)+" "+_vm._s(item.contactMobile))]),_c('div',{staticClass:"d-flex",staticStyle:{"flex-wrap":"wrap"}},[_vm._l((item.orderLists),function(i,index){return _c('div',{key:index,staticClass:"mr-1"},[_c('b',{staticStyle:{"opacity":"1","font-size":"x-large"},style:({ color: i.productColor })},[_vm._v(_vm._s(i.productCode)+" ")]),_vm._v(" / "+_vm._s(i.qty)+" ")])}),(item.orderPayment == 2)?_c('div',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(item.orderPayment == 2 ? item.totalPrice.toLocaleString("en-US") : "")+" ")]):_vm._e(),_c('div',[_vm._v(_vm._s(_vm.changeTextP(item.orderPayment)))])],2),_c('div',[_vm._v("Order#"+_vm._s(item.orderNo))]),_c('div',{staticStyle:{"font-size":"28px","font-weight":"bold"},domProps:{"innerHTML":_vm._s(item.orderDetail)}})])])}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }